import React from 'react'
import psBg from "../assets/psAssets/p&sBg.png"
import psInAcc from "../assets/psAssets/p&sInAcc.png"
import insurance1 from "../assets/psAssets/insurance1.png"
import insurance2 from "../assets/psAssets/insurance2.png"
import insurance3 from "../assets/psAssets/insurance3.png"

import psInsurance from "../assets/psAssets/p&sInsurance.png"


import { Link } from 'react-router-dom'
import { MdWifiCalling3 } from "react-icons/md";

import Banner from '../components/Banner'
import Footer from '../components/Footer'

const ProductSolution = () => {
  return (
    <div className='overflow-hidden'>
      <div className='relative'>
        <img src={psBg} alt='hero' className='w-full h-[629px] relative object-cover' />
        <div className="absolute inset-0 w-full h-full bg-black opacity-30"></div>
        <div className='absolute md:top-[303px] top-[180px] md:left-[160px] left-4 md:w-[745px] w-[365px] md:h-[165px] bg-[rgba(0,0,0,0.8)] p-10'>
          <h2 className='mb-4 text-[31px] leading-[37px] text-white font-normal'>Individual Insurance</h2>
          <p className='md:w-[686px] h-[128px] text-[18px] leading-[25px] text-white font-light'>Coronation Insurance Ghana Ltd offers comprehensive insurance for your car, home,
            business and life - with only one telephone call. Find out more & get a quote..
          </p>
        </div>
        <div className='absolute md:top-[181px] top-[270px] left-[15%] md:left-[1000px] 3xl:ml-[230px]'>
          <img src={psInAcc} alt='print' className='relative hidden md:flex' />
          <div className='absolute inset-0 bg-black opacity-30 rounded-[30px]'></div>
          <div className='absolute hidden md:flex flex-col top-[180px] items-center justify-center'>
            <h3 className='w-[263px] h-[16px] text-[13px] leading-[16px] font-normal mb-4 text-center text-white'>MY INSURANCE ACCOUNT</h3>
            <p className='w-[263px] h-[54px] text-[13px] leading-[18px] font-normal mb-6 text-center ml-[20px] text-white'>Want to know more about our products? <br />Let's talk</p>
          </div>
          <Link to="https://ecoronation.com/index" target='_blank' rel='noopener noreferrer' className='absolute top-[300px] items-center justify-center'>
            <button className='flex w-[263px] h-[45px] items-center gap-4 bg-[#B580D1] ml-[20px] justify-center text-white rounded-[30px]'>Contact Us <MdWifiCalling3 /></button>
          </Link>
        </div>
        <div className='md:w-[660px] w-[300px] h-[7px] bg-[#B580D1] absolute bottom-0'>
        </div>
      </div>

      <section>
        <div className='w-full flex items-center justify-center mb-10'>
          <div className='md:w-[1120px] h-[168px] flex flex-col'>
            <h2 className='md:text-[40px] text-[22px] md:leading-[52px] md:font-light font-normal text-center mt-10 mb-4 tracking-[-1px]'>LOOKING FOR THE RIGHT INSURANCE FOR YOU?
            </h2>
            <div className='md:w-[1111px] h-[63px] px-4 md:px-0'>
              <p className='font-light md:text-[24px] md:leading-[32px]'>
                Life doesn't wait, neither should your insurance coverage. Coronation Insurance Ghana Ltd offers comprehensive plans for car, home, business, and life. One call, and Total protection. Get a quote now!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-full flex flex-col items-center justify-center mt-[80px] mb-20 md:my-[100px]">
          <div className="w-[300px] md:w-[1121px] h-auto md:h-[272px] relative flex flex-col md:flex-row gap-4">
            <Link to="/motor">
              <div className="relative group">
                <img
                  src={insurance1}
                  alt="insure"
                  className="object-cover transition duration-300 ease-in-out group-hover:filter group-hover:grayscale"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black cursor-pointer rounded-[25px] opacity-0 transition duration-300 ease-in-out group-hover:opacity-50"></div>
                <div className="absolute top-[40%] left-0 w-full h-full flex flex-col items-center text-white">
                  <h3 className="w-[259px] h-[29px] md:font-normal font-bold text-[24px] leading-[29px] text-center">
                    Motor Insurance
                  </h3>
                  <p className="w-[280px] lg:w-[300px] h-[39px] font-normal text-[14px] leading-[19px] text-center">
                    Our car insurance plans are designed to help protect you, your vehicle and the ones you love. We also settle claims within 48 hours leaving you free to cruise on with absolute peace of mind.
                  </p>
                </div>
              </div>
            </Link>

            <Link to="/travel">
              <div className="relative group">
                <img
                  src={insurance2}
                  alt="insure"
                  className="object-cover transition duration-300 ease-in-out group-hover:filter group-hover:grayscale"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black cursor-pointer rounded-[25px] opacity-0 transition duration-300 ease-in-out group-hover:opacity-50"></div>
                <div className="absolute top-[40%] left-0 w-full h-full flex flex-col items-center text-white">
                  <h3 className="w-[259px] h-[29px] md:font-normal font-bold text-[24px] leading-[29px] text-center">
                    Travel Insurance
                  </h3>
                  <p className="w-[280px] lg:w-[300px] h-[39px] font-normal text-[14px] leading-[19px] text-center">
                    Travelling should be an enjoyable experience, our plans are designed to keep it that way.
                  </p>
                </div>
              </div>
            </Link>

            <Link to="/home_insurance">
              <div className="relative group">
                <img
                  src={insurance3}
                  alt="insure"
                  className="object-cover  transition duration-300 ease-in-out group-hover:filter group-hover:grayscale"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black cursor-pointer rounded-[25px] opacity-0 transition duration-300 ease-in-out group-hover:opacity-50"></div>
                <div className="absolute top-[40%] left-0 w-full h-full flex flex-col items-center text-white">
                  <h3 className="w-[259px] h-[29px] font-normal text-[24px] leading-[29px] text-center">
                    Home Insurance
                  </h3>
                  <p className="w-[280px] lg:w-[300px] h-[39px] md:font-normal font-bold text-[14px] leading-[19px] text-center">
                    Coronation Home Insurance protects your home inside and out, covering your building and belongings.
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <section>
        <div className='bg-[#F1F5F9] w-full lg:h-[794px] flex items-center justify-end'>
          <div className='lg:w-[1350px] lg:h-[552px] flex flex-col-reverse md:flex-row relative'>
            <div className='md:mt-[120px] flex flex-col 3xl:ml-[-30px] large:ml-[40px]'>
              <h2 className='md:h-[116px] p-4 md:p-0 text-center md:text-start w-[362] lg:text-[35px] text-[20px] lg:leading-[52px] md:font-light tracking-[-1px] lg:mt-4'>Get INSURANCE FROM
                CORONATION TODAY.</h2>
              <p className='md:w-[415px] p-4 md:p-0 lg:h-[96px] md:leading-[32px] md:text-[20px] font-light'>
                No one should have to wait more than 48 hours for their claims to be paid. That’s why we do it in less.
              </p>

              <div className='items-center flex justify-center md:justify-start pb-4 md:pb-0'>
                <Link to="https://claims-gh.coronation.ng/" target='_blank' rel='noopener noreferrer' className='w-[179px] h-[50px] bg-customPurple text-white mt-4 flex items-center justify-center'>
                  MAKE A CLAIM
                </Link>
              </div>
            </div>
            <div className="relative flex items-center justify-center">
              <div className="hidden md:block bg-black w-[840px] h-[286px]"></div>
              <img
                src={psInsurance}
                alt="history"
                className="w-full p-8 md:p-0 md:absolute md:w-[560px] h-auto md:h-[552px] object-cover md:top-0 md:right-[24%]"
              />
            </div>
          </div>
        </div>
      </section>

      <Banner />
      <Footer />
    </div>
  )
}

export default ProductSolution